* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.AppPage {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}