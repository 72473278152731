.login {
  height: 100vh;
  width: 100%;
  text-align: center;
  background-image: url("../../../public/loginback.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.loginform {
  width: 22rem;
  margin: auto;
  float: none;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-shadow: 5px 10px rgba(126, 182, 255, 0.8);
  background-color: rgba(202, 225, 255, 0.8);
}
