nav {
    background: rgba(225, 225, 231, 0.9);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    color: #000;
    position: relative;
    z-index: 99;
}
  
.fas {
    cursor: pointer;
}
  
.menu-icon {
    display: none;
    font-size: 20px;
}
  
.menu-icon .fa-times {
    transform: rotate(360deg);
    transition: all 0.2s ease-in-out;
}
  
.menu-icon .fa-bars {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
}
  
.logo img{
    height: 50px;
    width: 120px;
} 
  
.menu-list {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.menu-list li a {
    text-decoration: none;
    color: #000;
    font-weight: 300;
    padding: 5px 10px;
    margin-left: 20px;
}
  
.menu-list li a:hover{
    background-color: #fff;
    border-radius: 2px;
    color: #000;
    transition: all 0.5s ease-in-out;
}

.searchInputWrapper {
    position: relative;
}
  
.searchInput {
    width: 20rem;
    height: 2rem;
    padding: 0 1rem; 
    border-radius: 2rem;
    border: none;
    transition: transform 0.1s ease-in-out;
    text-align: center;
}
  
::placeholder {
    color: #a1a1a1;
}
  
  /* hide the placeholder text on focus */
:focus::placeholder {
    text-indent: -999px
}
  
.searchInput:focus {
    outline: none;
    transform: scale(1.1);
    transition: all 0.1s ease-in-out;
}
  
@media screen and (max-width: 1000px) {
    .searchInput{
        width: 15rem;
    }
}
  
@media screen and (max-width: 800px) {
    .menu-icon {
      display: block;
    }

    .searchInput{
        width: 10rem;
    }
    
    .menu-list {
      flex-direction: column;
      width: 100%;
      position: absolute;
      background: rgba(0, 0, 0, 0.7);
      padding: 20px;
      right: 0;
      top: 64px;
      transition: all 0.5s ease-in-out;
      margin-top: 10px;
    }
  
    .menu-list li {
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
    }
  
    .menu-list li a{
      width: 100px;
      display: block;
      margin: auto;
    }
  
    .menu-list.close {
      right: -100%;
      transition: all 0.5s ease-in-out;
    }
}