.centerHead {
  text-align: center;
}

.vll {
  border-left: 2px solid black;
  height: 450px;
}

.vlr {
  border-right: 2px solid black;
  height: 450px;
}

.centerText {
  text-align: center;
}

.legend {
  margin-top: 50px;
  font-size: 12px;
  color: darkgrey;
  text-align: left;
}

.pointer {
  cursor: pointer !important;
}